const prod = {
  API_URL: "https://account-api.urride.online/admin/",
  DEMO_DOMAIN: "demo.urride.online",
  STRIPE_PUBLISHABLE_KEY: "pk_live_f2M7v35a10Ngy1vLBtuZEvRL00ItDPSe68",
};
const testing = {
  API_URL: "https://urrideaccountapi.demo2project.com/admin/",
  DEMO_DOMAIN: "localhost",
  STRIPE_PUBLISHABLE_KEY: "pk_test_U2ccGGRSYmHlR0WPL70VyKGr00k3zFk6bN",
};
const dev = {
  API_URL: "https://urrideaccountapi.demo2project.com/admin/",
  // API_URL: "http://192.168.1.81:8000/admin/",
  // API_URL: "https://account-api.urride.online/admin/",
  DEMO_DOMAIN: "localhost",
  STRIPE_PUBLISHABLE_KEY: "pk_test_U2ccGGRSYmHlR0WPL70VyKGr00k3zFk6bN",
};

// export const config = process.env.NODE_ENV === "development" ? dev : prod;
export const config = process.env.NODE_ENV === "development" ? dev : testing;
