import React, { Component } from "react";

class Map extends Component {
  componentDidMount() {
    const { origin, destination, waypoints } = this.props;
    const directionsService = new window.google.maps.DirectionsService();
    const directionsRenderer = new window.google.maps.DirectionsRenderer();
    const map = new window.google.maps.Map(document.getElementById("map"), {
      zoom: 7,
      center: { lat: 37.7749, lng: -122.4194 },
      disableDefaultUI: true,
    });

    directionsRenderer.setMap(map);

    const waypointLocations = waypoints.map((point) => ({
      location: new window.google.maps.LatLng(point.lat, point.lng),
      stopover: true,
    }));

    // const origin = new window.google.maps.LatLng(37.7749, -122.4194);
    // const destination = new window.google.maps.LatLng(37.3382, -121.8863);

    const request = {
      origin: origin,
      destination: destination,
      travelMode: "DRIVING",
      waypoints: waypointLocations,
    };

    directionsService.route(request, (result, status) => {
      if (status === "OK") {
        directionsRenderer.setDirections(result);
      }
    });
  }

  render() {
    return <div id="map" style={{ width: "100%", minHeight: "500px" }}></div>;
  }
}

export default Map;
